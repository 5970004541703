<template>
  <div class="relative" v-bind="$attrs">
    <div
      ref="scrollable"
      class="scrollable flex select-none snap-x snap-mandatory scroll-p-2 list-none overflow-x-auto whitespace-nowrap p-2"
      :class="{ 'cursor-grab': groups.length > 1 }"
    >
      <slot name="items" />
    </div>
    <slot name="bullets" :bullets="groups" :go-to="goTo" />
    <slot name="arrows" :go-to-prev="goToPrev" :go-to-next="goToNext" />

    <div
      v-if="showArrows && groups.length > 1"
      class="flex flex-row items-center justify-center gap-2"
      :class="{ 'opacity-0': !isInitialized }"
    >
      <button
        class="absolute inset-y-0 left-4 top-1/2 size-10 -translate-y-1/2 rounded-xl bg-black/5 bg-[url('~/assets/pictograms/dynamic/chevron.svg?external')] bg-center bg-no-repeat transition-opacity duration-300 ease-in md:left-5"
        :class="hasPrev ? 'opacity-100' : 'opacity-20'"
        aria-label="Previous"
        @click="goToPrev()"
      />
      <button
        class="absolute right-4 top-1/2 size-10 -translate-y-1/2 rotate-180 rounded-xl bg-black/5 bg-[url('~/assets/pictograms/dynamic/chevron.svg?external')] bg-center bg-no-repeat transition-opacity duration-300 ease-in md:right-5"
        :class="{ 'opacity-20': !hasNext }"
        aria-label="Next"
        @click="goToNext()"
      />
    </div>
  </div>

  <div
    v-if="showBullets && groups.length > 1"
    class="mt-6 flex flex-row items-center justify-center md:mt-8"
  >
    <button
      v-for="bullet in groups"
      :key="bullet.index"
      class="transition-opacity duration-300 ease-in"
      :class="{
        'opacity-100': bullet.isTotallyVisible,
        'opacity-50': !bullet.isTotallyVisible && bullet.isVisible,
        'opacity-15': !bullet.isVisible,
      }"
      aria-label="Go to slide"
      @click="goTo(bullet.index)"
    >
      <span class="m-1 block size-2 rounded bg-black/54"></span>
    </button>
  </div>
  <div v-else-if="showBullets" class="mt-6 h-4 md:mt-8" />
</template>

<script setup lang="ts">
import useSwiper from './useSwiper';

const props = defineProps({
  showArrows: {
    type: Boolean,
    required: false,
    default: true,
  },
  showBullets: {
    type: Boolean,
    required: false,
    default: true,
  },
  activeGroup: {
    type: Number,
    required: false,
    default: 1,
  },
  gotoActiveGroup: {
    type: Number,
    required: false,
    default: 1,
  },
});

const emit = defineEmits(['groupChanged']);

const scrollable = ref<HTMLElement | null>(null);
const isInitialized = ref(false);

onMounted(() => {
  isInitialized.value = true;
});

const {
  groups,
  goTo,
  goToNext,
  goToPrev,
  hasNext,
  hasPrev,
  lastKnownVisibleGroupMin,
} = useSwiper(scrollable);

onMounted(() => {
  if (props.activeGroup && props.activeGroup !== 0) {
    goTo(props.activeGroup, false);
  } else if (props.gotoActiveGroup) {
    goTo(props.gotoActiveGroup, false);
  }
});

watch(
  () => props.gotoActiveGroup,
  (newGotoActiveGroup) => {
    nextTick(() => {
      goTo(newGotoActiveGroup, false);
    });
  },
  { immediate: false }
);

watch(lastKnownVisibleGroupMin, (newActiveGroup) => {
  emit('groupChanged', newActiveGroup);
});
</script>

<style scoped>
/* Hide scrollbar for Chrome, Safari and Opera */
.scrollable::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.scrollable {
  -ms-overflow-style: none;
  scrollbar-width: none;
  overscroll-behavior-x: contain;
}

.buttoon-next,
.buttoon-prev {
  height: 2.5rem;
  width: 2.5rem;
  background-color: rgba(0, 0, 0, 0.06);
  background-position: 50%;
  background-repeat: no-repeat;
  border-radius: 0.75rem;
  background-image: url('~/assets/pictograms/static/chevron.svg?external');
}
.buttoon-prev {
  left: 0;
}
.buttoon-next {
  transform: rotate(180deg);
  right: 0;
}
</style>
