import { ref, computed, watch, onMounted, onUnmounted } from 'vue';

interface Item {
  index: number;
  width: number;
  group: number;
  isVisible: boolean;
  isLastVisible: boolean;
  isFirstVisible: boolean;
}

interface Group {
  index: number;
  items: number[];
  width: number;
  isVisible: boolean;
  isTotallyVisible: boolean;
}

interface GroupAccumulator {
  [key: number]: Group;
}
export default function useSwiper(scrollable: { value: HTMLElement | null }) {
  /**
   * Drag and Drop for CSS-based Swiper Component
   *
   * Overview:
   * - Adds mouse drag functionality to swiper components, enhancing interaction on desktop browsers.
   *
   * Features:
   * - Manages drag state with an elastic overscroll effect for intuitive boundary interactions.
   * - Temporarily disables CSS scroll-snap during drag to enable custom behavior, restoring it on drag end.
   * - Prevents click actions during drag to avoid unintended link navigation or other interactions.
   */

  const DRAG_SENSITIVITY_THRESHOLD = 5;
  const OBSERVER_VISIBILITY_THRESHOLD = 0.7;
  const ELASTIC_OVERSCROLL_DIVISION = 3;
  const GROUPING_TOLERANCE = 5;
  const SCROLL_SNAP_DELAY = 2000; // Time in milliseconds

  const dragStartX = ref(0);
  const dragEndX = ref(0);
  const dragChangeX = ref(0);
  const scrollLeft = ref(0);
  const isDown = ref(false);
  const isDragging = ref(false);
  const isScrolling = ref(false);
  const scrollBehavior = ref('auto');
  const scrollTimeout = ref<null | NodeJS.Timeout>(null);

  const timeoutId = ref<null | NodeJS.Timeout>(null);

  const onlyOneGroup = computed(() => groups?.value?.length <= 1);

  const startDragging = (e: MouseEvent) => {
    if (onlyOneGroup.value || !scrollable.value) return;
    e.preventDefault();
    e.stopImmediatePropagation();
    isDown.value = true;
    isDragging.value = false;
    dragStartX.value = e.pageX - scrollable.value.offsetLeft;
    scrollLeft.value = scrollable.value.scrollLeft;
    const scrollableValue = scrollable.value;
    scrollableValue.style.cursor = 'grabbing';
    scrollableValue.style.scrollSnapType = 'none';
    scrollableValue.style.scrollBehavior = 'auto';
    resetTimeout();
  };

  const handleDragging = (e: MouseEvent) => {
    if (onlyOneGroup.value || !scrollable.value || !isDown.value) return;
    const x = e.pageX - scrollable.value.offsetLeft;
    const walk = x - dragStartX.value;

    // Check if user is dragging - this is used to prevent all clicks globally
    if (Math.abs(walk) > DRAG_SENSITIVITY_THRESHOLD) {
      isDragging.value = true;
    }

    // Calculate the potential new scroll position based on the current horizontal drag distance.
    const newScrollPosition = scrollLeft.value - walk;

    // Determine the maximum possible scroll position to ensure scrolling stays within the content width.
    const maxScrollLeft =
      scrollable.value.scrollWidth - scrollable.value.clientWidth;

    // Check if the new scroll position is beyond the content's scrollable boundaries.
    if (newScrollPosition < 0 || newScrollPosition > maxScrollLeft) {
      // Calculate how much the scroll position exceeds the boundaries.
      let overscroll =
        newScrollPosition < 0
          ? -newScrollPosition
          : newScrollPosition - maxScrollLeft;

      // Apply a division to reduce the overscroll effect, making it elastic.
      // This division factor softens the elasticity, making the overscroll visually more gradual.
      overscroll /= ELASTIC_OVERSCROLL_DIVISION;

      // Create a new variable to store the value of scrollable.value
      const scrollableValue = scrollable.value;

      // Apply a CSS transform to visually shift the content in the direction of the overscroll.
      // This creates a 'rubber band' effect where content is temporarily pulled beyond its limits and snaps back.
      scrollableValue.style.transform = `translateX(${newScrollPosition < 0 ? overscroll : -overscroll}px)`;
    } else {
      // If within bounds, update the actual scroll position and reset any transformations applied during the overscroll.
      const scrollableValue = scrollable.value;
      scrollableValue.scrollLeft = newScrollPosition;
      scrollableValue.style.transform = 'translateX(0px)';
    }
  };

  const stopDragging = (e: MouseEvent) => {
    if (onlyOneGroup.value || !scrollable.value) return;
    if (!isDown.value) return;

    isDown.value = false;
    dragEndX.value = e.pageX - scrollable.value.offsetLeft;
    dragChangeX.value = dragStartX.value - dragEndX.value;

    const scrollableValue = scrollable.value;
    scrollableValue.style.scrollBehavior = 'smooth';
    scrollableValue.style.cursor = 'grab';

    // Check if the change is within the -5 to 5 range
    if (Math.abs(dragChangeX.value) > 5) {
      if (dragChangeX.value > 0) {
        goTo(lastKnownVisibleGroupMin.value + 1);
      } else {
        goTo(lastKnownVisibleGroupMax.value - 1);
      }
    } else {
      scrollableValue.style.scrollSnapType = 'x mandatory';
    }

    // Reset transformation and dragging state
    scrollableValue.style.transform = 'translateX(0px)';

    // If we set this without a timeout, the click event will be triggered if there is a link in the content
    setTimeout(() => {
      isDragging.value = false;
    }, 100);

    // Reset the scroll snap type after a delay to prevent immediate snapping
    resetTimeout();
    timeoutId.value = setTimeout(() => {
      if (scrollableValue) {
        scrollableValue.style.scrollSnapType = 'x mandatory';
        scrollableValue.style.scrollBehavior = 'auto';
      }
      timeoutId.value = null;
    }, SCROLL_SNAP_DELAY);
  };

  // the timeout is used to prevent the scroll snap from snapping immediately after dragging
  function resetTimeout() {
    if (timeoutId.value) {
      clearTimeout(timeoutId.value);
      timeoutId.value = null;
    }
  }

  // Global click handler to prevent click events during drag
  function handleClickDuringDrag(e: MouseEvent) {
    if (isDragging.value) {
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  }

  onMounted(() => {
    if (!scrollable.value) return;
    scrollable.value.addEventListener('mouseup', stopDragging);
    scrollable.value.addEventListener('mousemove', handleDragging);
    scrollable.value.addEventListener('mousedown', startDragging);
    scrollable.value.addEventListener('click', handleClickDuringDrag, true);
    document.addEventListener('mouseup', stopDragging);
  });

  onUnmounted(() => {
    if (!scrollable.value) return;
    scrollable.value.removeEventListener('mouseup', stopDragging);
    scrollable.value.removeEventListener('mousemove', handleDragging);
    scrollable.value.removeEventListener('mousedown', startDragging);
    scrollable.value.removeEventListener('click', handleClickDuringDrag, true);
    document.removeEventListener('mouseup', stopDragging);
  });

  /**
   * Dynamic Grouping of Child Elements in a Scrollable Container
   *
   * Overview:
   * - Manages child elements by dynamically grouping them based on their collective width relative to the container's width.
   * - Assigns group numbers to children for use in pagination or navigation bullets in carousels or swiper components.
   *
   * Key Functions:
   * - updateItems: Recalculates groups to reflect changes in the container's or children's width.
   * - onResize: Responds to window resize events to ensure groupings remain accurate.
   * - goTo: Adjusts the scroll position to navigate to a designated group.
   *
   * Benefits:
   * - Ensures synchronization of swiper's pagination controls with visible content, improving usability and interactivity.
   */

  const items = ref<Item[]>([]);

  const updateItems = () => {
    // Early exit if no scrollable ref is defined
    if (!scrollable.value) return;

    // Retrieve computed styles to get dynamic measurements
    const style = getComputedStyle(scrollable.value);

    // Get the gap between items, defaulting to 0 if not set
    const gap =
      Math.floor(parseFloat(style.columnGap) || parseFloat(style.gap)) || 0;

    // Retrieve horizontal padding to adjust calculation of available space
    const paddingLeft = Math.floor(parseFloat(style.paddingLeft)) || 0;
    const paddingRight = Math.floor(parseFloat(style.paddingRight)) || 0;

    // Calculate the net available width inside the scrollable container
    const parentWidth =
      scrollable.value.offsetWidth - paddingLeft - paddingRight;

    // Variables to track group widths and indices
    let currentGroupWidth = 0;
    let currentGroupIndex = 1;
    const resultArray: { index: number; width: number; group: number }[] = [];

    // Loop through each child element to determine its group based on accumulated width
    Array.from(scrollable.value.children).forEach((child, index) => {
      // Calculate the width of the child including the gap if it's not the last item
      const childWidthWithGap =
        (child as HTMLElement).offsetWidth +
        (index < (scrollable.value?.children.length ?? 0) - 1 ? gap : 0);

      // If adding this child would exceed the available width, start a new group
      if (
        currentGroupWidth + childWidthWithGap >
          parentWidth + GROUPING_TOLERANCE &&
        currentGroupWidth > 0
      ) {
        currentGroupIndex += 1;
        currentGroupWidth = 0;
      }

      // Add the child's width to the current group's total width
      currentGroupWidth += childWidthWithGap;

      // Push the current child's data into the results array
      resultArray.push({
        index,
        width: childWidthWithGap,
        group: currentGroupIndex,
      });
    });

    // Store the grouped items back to the Vue reactive data property
    items.value = resultArray.map((item) => ({
      ...item,
      isVisible: false,
      isLastVisible: false,
      isFirstVisible: false,
    }));
  };

  // Handle window resize
  const onResize = () => {
    updateItems();
  };

  onMounted(() => {
    if (scrollable.value) {
      window.addEventListener('resize', onResize);
      updateItems();
    }
  });

  onUnmounted(() => {
    if (scrollable.value) {
      window.removeEventListener('resize', onResize);
    }
  });

  const visibleItems = ref<number[]>([]);
  const lastKnownVisibleItems = ref<number[]>([]);
  const lastKnownVisibleItemsMax = ref(0);
  const lastKnownVisibleItemsMin = ref(0);
  const itemsWithVisibility = computed(() =>
    items.value.map((item: Item) => ({
      ...item,
      isVisible: lastKnownVisibleItems.value.includes(item.index),
      isLastVisible: lastKnownVisibleItemsMax.value === item.index,
      isFirstVisible: lastKnownVisibleItemsMin.value === item.index,
    }))
  );

  // A group is a collection of items that are gouped together based on the available width.
  // Its used for bullets and next/prev buttons

  const groups = computed((): Group[] => {
    const grouped = itemsWithVisibility.value.reduce(
      (acc: GroupAccumulator, item: Item) => {
        const groupKey = item.group;
        if (!acc[groupKey]) {
          acc[groupKey] = {
            index: groupKey,
            items: [item.index],
            width: item.width,
            isVisible: item.isVisible,
            isTotallyVisible: item.isVisible, // Start assuming total visibility is equivalent to this first item's visibility
          };
        } else {
          acc[groupKey].items.push(item.index);
          acc[groupKey].width += item.width;
          acc[groupKey].isVisible = acc[groupKey].isVisible || item.isVisible;
          // Ensure that isTotallyVisible only remains true if all items are visible
          acc[groupKey].isTotallyVisible =
            acc[groupKey].isTotallyVisible && item.isVisible;
        }
        return acc;
      },
      {}
    );

    return Object.values(grouped);
  });

  // Get the min and max group index of the last known visible items
  const lastKnownVisibleGroupMax = computed(() =>
    lastKnownVisibleItems.value
      .map((item) => itemsWithVisibility.value[item].group)
      .reduce((acc, group) => (group > acc ? group : acc), 0)
  );

  const lastKnownVisibleGroupMin = computed(() =>
    lastKnownVisibleItems.value
      .map((item) => itemsWithVisibility.value[item].group)
      .reduce((acc, group) => (group < acc ? group : acc), Infinity)
  );

  // Function to scroll to a specific group index
  function goTo(groupIndex: number, smooth = true) {
    if (!scrollable.value || !groups.value.length) return;

    // Initialize the scroll position
    let scrollPosition = 0;

    // Scrolling behavior, if smooth is enabled and not already scrolling
    scrollBehavior.value =
      smooth && isScrolling.value === false ? 'smooth' : 'auto';
    if (groupIndex === 0) {
      scrollable.value.scrollTo({
        left: 0,
        behavior: scrollBehavior.value,
      });
      return;
    }

    if (groupIndex > groups.value.length) {
      return;
    }

    // Calculate the scroll position based on the widths of all groups up to the target
    for (let i = 0; i < groups.value.length; i += 1) {
      if (groups.value[i].index === groupIndex) {
        break; // Stop adding widths once the target group is reached
      }
      scrollPosition += groups.value[i].width;
    }

    // Scroll to the calculated position
    scrollable.value.scrollTo({
      left: scrollPosition,
      behavior: scrollBehavior.value,
    });

    if (scrollTimeout.value) {
      clearTimeout(scrollTimeout.value);
    }

    isScrolling.value = true;

    // Set the scrolling state to true for a brief period
    scrollTimeout.value = setTimeout(() => {
      isScrolling.value = false;
    }, 300);
  }

  // Computed property to determine if there is a previous group to scroll to
  const hasPrev = computed(() => {
    if (
      lastKnownVisibleGroupMin.value === 1 &&
      lastKnownVisibleGroupMax.value === 1
    ) {
      return false;
    }
    return true;
  });

  // Computed property to determine if there is a next group to scroll to
  const hasNext = computed(() => {
    const totalGroups = groups.value.length;
    return lastKnownVisibleGroupMax.value < totalGroups;
  });

  function goToPrev() {
    goTo(lastKnownVisibleGroupMin.value - 1);
  }

  function goToNext() {
    goTo(lastKnownVisibleGroupMax.value + 1, true);
  }

  /**
   * Visibility Tracking for Swiper Items
   *
   * Overview:
   * - Monitors visibility changes of swiper items using an IntersectionObserver to dynamically update navigation controls.
   *
   * Purpose:
   * - Ensures swiper controls (bullets and arrows) accurately reflect the current visibility state of items, which can change during user interaction such as touch, scrolling, or dragging.
   *
   * Functionality:
   * - As items become visible or hidden, state updates are triggered to synchronize navigation controls with the user's view, thereby enhancing the interactivity and user experience of the swiper.
   */
  let observerInstance: IntersectionObserver | null = null;

  // Update control states based on visible items
  watch(visibleItems, (newValue) => {
    if (newValue.length === 0) {
      return; // No visible items, no updates required
    }
    const max = Math.max(...newValue);
    const min = Math.min(...newValue);
    lastKnownVisibleItemsMax.value = max; // Most right visible item
    lastKnownVisibleItemsMin.value = min; // Most left visible item
    lastKnownVisibleItems.value = newValue; // Current set of visible items
  });

  // Adds an item to the visible list if not already present
  const addVisibleItem = (index: number) => {
    nextTick(() => {
      if (!visibleItems.value.includes(index)) {
        visibleItems.value = [...visibleItems.value, index] as number[];
      }
    });
  };

  // Removes an item from the visible list
  const removeVisibleItem = (index: number) => {
    nextTick(() => {
      visibleItems.value = visibleItems.value.filter((item) => item !== index);
    });
  };

  // Setup the IntersectionObserver on mount to track item visibility changes
  onMounted(() => {
    if (!scrollable.value) return;
    const sections = Array.from(scrollable.value.children);
    const observerOptions = {
      threshold: OBSERVER_VISIBILITY_THRESHOLD, // Threshold for when an item is considered visible
    };
    observerInstance = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const index = sections.indexOf(entry.target);
        if (entry.isIntersecting) {
          addVisibleItem(index);
        } else {
          removeVisibleItem(index);
        }
      });
    }, observerOptions);
    sections.forEach((section) => {
      if (observerInstance) {
        observerInstance.observe(section);
      }
    });
  });

  // Clean up the observer on component unmount
  onUnmounted(() => {
    if (observerInstance) {
      observerInstance.disconnect();
    }
  });

  /**
   * Prevent Accidental Touchpad Navigation
   *
   * Overview:
   * - Disables touchpad swipe gestures from mistakenly activating browser's back/forward navigation.
   */

  function handleMouseWheel(event: WheelEvent) {
    if (!scrollable.value) return;
    const { scrollWidth } = scrollable.value;
    const { clientWidth } = scrollable.value;
    const maxX = scrollWidth - clientWidth;
    const nextScrollLeft = scrollable.value.scrollLeft + event.deltaX;

    // Prevent scrolling beyond the content width.
    if (nextScrollLeft < 0 || nextScrollLeft > maxX) {
      event.preventDefault();
      const scrollableValue = scrollable.value;
      scrollableValue.scrollLeft = Math.max(0, Math.min(maxX, nextScrollLeft));
    }
  }

  // Sets up mouse wheel handling to prevent undesirable scroll behaviors.
  function setupMouseWheelHandling() {
    if (!scrollable.value) return;
    scrollable.value.addEventListener('wheel', handleMouseWheel, false);
  }

  onMounted(() => {
    if (scrollable.value) {
      setupMouseWheelHandling();
    }
  });

  onUnmounted(() => {
    if (scrollable.value) {
      scrollable.value.removeEventListener('wheel', handleMouseWheel, false);
    }
  });

  return {
    isScrolling,
    scrollBehavior,
    groups,
    goTo,
    goToNext,
    goToPrev,
    hasNext,
    hasPrev,
    lastKnownVisibleGroupMin,
    lastKnownVisibleGroupMax,
  };
}
